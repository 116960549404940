.ui,
.notifications {
	font-family: 'Lars', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;

	font-size: 18px;
}

a {
	color: inherit;
}

.ui.visible + .ui-toggle-wrapper .ui-toggle {
	transform: rotate(90deg);
	background-color: transparent;
	box-shadow: none;
}

.ui-toggle-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9999;
	height: 45px;
	display: flex;
	padding: 20px;
	box-sizing: border-box;
	align-items: center;

	.ui-toggle {
		white-space: nowrap;
		border: 1px solid #fff;
		border-radius: 50px;
		appearance: none;
		cursor: pointer;
		font-size: 13px;
		font-family: 'Lars Mono', monospace;
		display: flex;
		box-sizing: border-box;
		width: 27px;
		height: 27px;
		background-position: 45% 50%;
		background-repeat: no-repeat;
		transition: background 0.1s ease, transform 0.3s ease;
		background-image: url('../../svg/icon-back.svg');
		transform: rotate(-90deg);

		background-color: rgba(0, 0, 0, 0.2);
		box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);

		&:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}

	// width: 40px;
	// height: 30px;
	// background: rgba(0, 0, 0, 0.15);
	// -webkit-backdrop-filter: blur(10px);
	// backdrop-filter: blur(10px);
	// cursor: pointer;
	// border-radius: 0 0 10px 10px;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	// font-size: 12px;
	// color: #fff;

	// &::after {
	// 	content: '\25bc';
	// }
}

.ui {
	position: fixed;
	left: 0;
	top: 0;

	width: 100%;
	// flex: 0;

	z-index: 2000;

	transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transform: translateY(-100%);

	&.visible {
		transform: translateY(0);
	}

	.container {
		flex: 1;
	}

	.network-stats {
		position: absolute;
		width: 302px;
		left: 40px;
		top: 145px;

		background: rgba(0, 0, 0, 0.2);
		mix-blend-mode: normal;
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		border-radius: 10px;

		color: #fff;
		flex-direction: column;
		padding: 20px;
		box-sizing: border-box;
		gap: 1em;

		display: none;

		&.visible {
			display: flex;
		}

		.network-stat {
			display: flex;
			flex-direction: column;
			gap: 0.3em;
			flex: 1;
		}

		.graph {
			box-sizing: border-box;

			width: calc(100% + 10px);
			margin-left: -5px;
			height: 17px;

			border: 1px solid #ffffff;
			border-radius: 20px;
			overflow: hidden;

			.graph-fill {
				height: 100%;
				border-radius: 20px;
				background-color: #ffffff;
				padding-left: 100px;
				margin-left: -100px;
			}
		}

		.value {
			font-size: 13px;
		}

		.label {
			font-family: 'Lars Mono', monospace;
			font-style: normal;
			font-weight: 400;
			font-size: 10px;

			color: #ffffff;

			opacity: 0.7;
		}
		.network-stat-discovered {
			.graph {
				border-color: #fff;

				.graph-fill {
					background-color: #fff;
				}
			}
		}

		.network-stat-bookmarked {
			.graph {
				border-color: #a850ff;

				.graph-fill {
					background-color: #a850ff;
				}
			}
		}

		.network-stat-minted {
			.graph {
				border-color: #31ffda;

				.graph-fill {
					background-color: #31ffda;
				}
			}
		}
	}

	.header-upper,
	.header-lower {
		height: 45px;
		padding: 0 20px;

		display: flex;
		align-items: center;

		color: #fff;
		// z-index: 1;

		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
	}

	.header-upper {
		background: rgba(0, 0, 0, 0.4);
		position: relative;
		padding: 0 57px;
	}

	.header-lower {
		height: 45px;
		padding: 0 20px;
		border-radius: 0 0 10px 10px;

		position: relative;

		overflow: hidden;
		z-index: -1;
	}

	.header-lower.minted,
	.header-lower.proof {
		color: #000;
	}

	.header-lower-background,
	.header-lower-background div {
		position: absolute;

		left: 0;
		right: 0;
		bottom: 0;

		z-index: -1;

		transition: top 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		overflow: hidden;
	}

	.header-lower-background {
		top: 0;
	}

	.header-lower-background div {
		height: 200%;
	}

	.header-lower-background-main {
		background: rgba(0, 0, 0, 0.15);
		top: 0;
		z-index: -3;
	}

	.header-lower-background-bookmarked {
		background: linear-gradient(#a850ff00 0%, #a850ffff 50%, #a850ffff 100%)
			no-repeat;
		top: 100%;
		z-index: -2;
	}

	.header-lower-background-minted {
		background: linear-gradient(#31ffda00 0%, #31ffdaff 50%, #31ffdaff 100%)
			no-repeat;
		top: 100%;
		z-index: -1;
	}

	.header-lower-background-proof {
		background: linear-gradient(#ffba1200 0%, #ffba12 50%, #ffba12ff 100%)
			no-repeat;
		top: 100%;
		z-index: -1;
	}

	.header-lower.bookmarked .header-lower-background-bookmarked,
	.header-lower.minted .header-lower-background-minted,
	.header-lower.proof .header-lower-background-proof {
		top: -100%;
	}

	button {
		white-space: nowrap;
		border: 1px solid #fff;
		border-radius: 50px;
		appearance: none;
		background-color: transparent;
		font-family: inherit;
		font-size: inherit;
		color: inherit;
		cursor: pointer;
		font-size: 13px;
		font-family: 'Lars Mono', monospace;
		padding: 4px 9px;
		transition: background 0.1s ease;
		display: flex;
		align-items: center;
		line-height: 1em;
		box-sizing: border-box;
		min-width: 27px;
		min-height: 27px;
		background-position: center;
		background-repeat: no-repeat;
	}

	button:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}

	.button-big {
		font-family: 'Lars', sans-serif;
	}

	.header-section-about,
	.header-section-nav,
	.header-section-market,
	.header-section-stats,
	.header-section-title,
	.header-section-action {
		flex: 1;
	}

	.header-section-about {
		display: flex;
		gap: 12px;
	}

	.header-section-nav {
		flex: 0;
	}

	.header-section-title {
		flex: 0;
		white-space: nowrap;
		font-size: 15px;
		font-style: italic;
		font-weight: bold;
	}

	.stats {
		display: flex;
		align-items: center;
		font-size: 13px;
		font-family: 'Lars Mono', monospace;

		.stats-views,
		.stats-bookmarks {
			opacity: 0.6;
			margin-right: 20px;
			display: flex;
			align-items: center;
		}

		.stats-views::before {
			content: '';
			display: block;
			margin-right: 5px;
			width: 20px;
			height: 12px;
			mask-image: url('../../svg/icon-view.svg');
			-webkit-mask-image: url('../../svg/icon-view.svg');
			background: #fff;
		}

		.stats-bookmarks::before {
			content: '';
			display: block;
			margin-right: 5px;
			width: 13px;
			height: 12px;
			mask-image: url('../../svg/icon-bookmark.svg');
			-webkit-mask-image: url('../../svg/icon-bookmark.svg');
			background: #fff;
		}
	}

	.header-section-market,
	.header-section-action {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-family: 'Lars Mono', monospace;
	}

	.icon-bookmark {
		display: block;
		margin-right: 10px;
		width: 13px;
		height: 12px;
		mask-image: url('../../svg/icon-bookmark-filled.svg');
		-webkit-mask-image: url('../../svg/icon-bookmark-filled.svg');
		background-image: linear-gradient(#fff0 0, #ffff 1px);
		background-repeat: no-repeat;
		background-position: 0 12px;
		position: relative;
		transition: background-position 0.3s
			cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.header-lower.minted .icon-bookmark,
	.header-lower.proof .icon-bookmark {
		background-image: linear-gradient(#0000 0, #000f 1px);
	}

	.header-lower.bookmarked .button-bookmark {
		background: rgba(255, 255, 255, 0.2);
	}

	.button-bookmark {
		position: relative;
	}

	.button-bookmark::after {
		content: 'Bookmark';
		margin-left: 20px;
	}

	.header-lower.minted .stats-views::before,
	.header-lower.minted .stats-bookmarks::before,
	.header-lower.proof .stats-views::before,
	.header-lower.proof .stats-bookmarks::before {
		background: #000;
	}

	.header-lower.minted .button-bookmark,
	.header-lower.proof .button-bookmark {
		border: 1px solid #000;
	}

	.button-bookmark .icon-bookmark {
		position: absolute;
		left: 13.5px;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 13px;
		height: 12px;
	}

	.button-bookmark .icon-bookmark::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 13px;
		height: 12px;
		mask-image: url('../../svg/icon-bookmark.svg');
		-webkit-mask-image: url('../../svg/icon-bookmark.svg');
		background-color: #fff;
	}

	.header-lower.minted .button-bookmark .icon-bookmark::before,
	.header-lower.proof .button-bookmark .icon-bookmark::before {
		background: #000;
	}

	.header-lower.bookmarked .button-bookmark .icon-bookmark {
		background-position: 0 0px;
	}

	.header-lower.bookmarked.minted .button-bookmark,
	.header-lower.minted .button-bookmark:hover,
	.header-lower.bookmarked.proof .button-bookmark,
	.header-lower.proof .button-bookmark:hover {
		background: rgba(0, 0, 0, 0.2);
	}

	.overlay-controls {
		position: absolute;
		left: 0;
		top: 0;

		height: 45px;
		display: flex;

		align-items: center;
		gap: 10px;
		margin-left: 20px;

		z-index: 1;
	}

	.overlay {
		a {
			color: inherit;
		}
		box-shadow: 0 0 50px rgba(0, 0, 0, 0);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 500px;
		height: 100vh;
		background: rgba(0, 0, 0, 0.6);
		-webkit-backdrop-filter: blur(50px);
		backdrop-filter: blur(50px);
		border-radius: 0px 10px 10px 0px;
		padding: 0 20px;
		box-sizing: border-box;
		color: #fff;
		transform: translateX(-100%);
		display: flex;
		flex-direction: column;
		transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
			box-shadow 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

		&.visible {
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.7);
			transform: translateX(0);
		}

		.top-bar {
			flex: 0 0 45px;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.overlay-title,
		.overlay-title-2 {
			height: 44px;
			flex: 0 0 44px;
			font-size: 1.2em;
			width: 100%;
			margin-left: -20px;
			padding-left: 20px;
			padding-right: 20px;
			display: flex;
			align-items: center;
			border-top: 0.25px solid rgba(255, 255, 255, 0.25);
			border-bottom: 0.25px solid rgba(255, 255, 255, 0.25);
			font-weight: bold;
		}
	}

	.about-content,
	.manual-content {
		padding: 20px 0;
		overflow: scroll;
	}

	em {
		font-style: italic;
	}

	.overlay-bookmarks {
		.bookmarks,
		.mints {
			flex: 1;
			width: 100%;
			padding: 0 20px;
			margin-left: -20px;
			overflow: scroll;

			.bookmark {
				border-bottom: 0.25px solid rgba(255, 255, 255, 0.25);
				width: 100%;
				padding: 10px 20px;
				margin-left: -20px;
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 15px;

				position: relative;

				&:hover {
					background: rgba(255, 255, 255, 0.2);
				}

				.bookmark-metadata {
					gap: 6px;
					display: flex;
					flex-direction: column;

					.bookmark-title {
						font-style: italic;
						font-weight: bold;
					}
				}

				.bookmark-icon {
					width: 16px;
					height: 16px;
				}
			}
		}

		.overlay-title {
			background-color: #a850ff;
		}

		.overlay-title-2 {
			background-color: #31ffda;
			color: #000;
		}
	}

	.header-lower.minted .mint-price {
		display: none;
	}

	.mint-price {
		white-space: nowrap;
		margin-right: 20px;
	}

	.button-bookmarks {
		background-image: url('../../svg/icon-bookmarks.svg');
	}

	.button-about {
		background-image: url('../../svg/icon-about.svg');
	}

	.button-manual {
		width: 27px;
		color: #fff;
		font-family: 'Lars Mono', monospace;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.button-close {
		background-image: url('../../svg/icon-close.svg');
	}

	canvas {
		display: block;
	}

	.button-about,
	.button-bookmarks {
		z-index: 9999;

		&.active {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}

	.button-explore::after {
		content: 'Web';
	}

	.button-explore.active::after {
		content: 'Back to Webpage';
	}

	.button-explore.active::before {
		content: '\00a0';
		display: block;
		width: 10px;
		margin-right: 10px;
		height: 100%;
		background-image: url('../../svg/icon-back.svg');
		background-position: center;
		background-repeat: no-repeat;
	}

	.header-section-market {
		gap: 10px;
	}

	.market-information {
		position: relative;
		white-space: nowrap;

		// &::after {
		// 	position: absolute;
		// 	content: 'NOT UP TO DATE';
		// 	font-size: 8px;
		// 	background: red;
		// 	color: white;
		// 	padding: 3px 10px;
		// 	border-radius: 50px;
		// 	transform: translate(50%, 50%) rotate(-10deg);
		// 	right: 20px;
		// 	bottom: 10px;
		// }
	}

	.button-wallet {
		&.button-wallet-disconnect {
			height: 27px;
			overflow: hidden;
			white-space: nowrap;

			max-width: 250px;

			div {
				// display: flex;
				flex-direction: column;
				height: 54px;
				// gap: 0px;
				transform: translateY(25%);
				transition: transform 0.3s
					cubic-bezier(0.455, 0.03, 0.515, 0.955);
				text-align: center;
				display: flex;
				align-items: center;

				span {
					display: flex;
					height: 27px;
					align-items: center;
				}
			}

			&:hover {
				div {
					transform: translateY(-25%);
				}
			}
		}
	}

	.header-lower.minted .button-mint {
		display: none;
	}

	// .header-lower:not(.minted) .minted-by {
	// 	display: none;
	// }

	.button-mint,
	.button-mint-random {
		border-color: #31ffda;
		color: #31ffda;

		&:hover {
			background-color: rgba(49, 255, 218, 0.2);
		}
	}

	.minted-by {
		font-size: 13px;
	}
}

.notifications {
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: calc(100% - 40px);
	display: flex;
	flex-direction: column;
	max-width: 250px;
	gap: 10px;
	z-index: 10000;

	@keyframes notification {
		0% {
			opacity: 0;
			margin-bottom: -80px;
		}

		100% {
			opacity: 1;
			margin-bottom: 0px;
		}
	}

	.notification {
		background: #31ffda;

		height: 70px;
		transform: translate(calc(100% + 20px), 0);
		transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		animation: notification 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

		border-radius: 10px;
		margin-bottom: 0px;

		display: flex;
		flex-direction: column;
		gap: 8px;

		justify-content: center;

		box-sizing: border-box;
		padding: 0 10px;

		a {
			color: inherit;
		}

		&.notification-transaction {
			@keyframes gradient {
				0% {
					background-position: 0% 0%;
				}

				100% {
					background-position: -300px 0%;
				}
			}

			animation: gradient 2s linear infinite;
			background: repeating-linear-gradient(
				90deg,
				rgb(206, 121, 255) 0,
				rgba(49, 255, 218, 1) 50%,
				rgb(206, 121, 255) 100%
			);
			background-size: 300px;
		}

		&.notification-transaction .notification-title::after {
			@keyframes spinner {
				0% {
					content: '-';
				}

				25% {
					content: '\\';
				}

				50% {
					content: '|';
				}

				75% {
					content: '/';
				}

				100% {
					content: '-';
				}
			}

			font-style: normal;
			font-family: 'Lars Mono', monospace;
			content: '-';
			animation: spinner 0.5s linear infinite;
			margin-left: 10px;
		}

		.notification-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-style: italic;
			padding-bottom: 2px;
		}

		.labels {
			display: flex;
			flex-direction: row;
			gap: 10px;

			.labeled {
				flex: 1 1 auto;
				min-width: 40px;

				.label {
					opacity: 0.4;
				}

				.value {
					position: relative;
					white-space: nowrap;
				}
			}
		}

		&.visible {
			transform: translate(0, 0);
		}

		.price {
			flex: 1 1 auto;

			.value::after {
				content: ' \039e';
			}
		}

		.address .value {
			overflow-x: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.map-container {
	display: none;

	height: 100%;
	width: 100%;

	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1000;

	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.02) 0%,
		rgba(0, 0, 0, 0.3) 20%
	);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);

	pointer-events: none;
}

.labeled {
	display: flex;
	flex-direction: column;

	gap: 2px;

	.value {
		font-size: 13px;
	}

	.label {
		font-size: 10px;
		opacity: 0.7;
	}
}

.map-container.visible {
	display: block;
	pointer-events: auto;
}

wcm-modal {
	position: fixed;
	z-index: 9999999999999;
}

.green {
	color: #31ffda;
}

.modal-background {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: #0009;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);

	position: fixed;
	display: none;
	z-index: 999999;

	&.visible {
		display: flex;
	}
}

.modal {
	position: fixed;

	background: #fff2;
	border: 1px solid #fff;
	-webkit-backdrop-filter: blur(20px);
	backdrop-filter: blur(20px);
	box-shadow: 0 20px 40px 0px #0006;

	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	z-index: 9999999;
	padding: 32px 48px;
	border-radius: 15px;
	box-sizing: border-box;
	max-width: 400px;
	width: 100%;

	color: #fff;
	font-family: 'Lars Mono', monospace;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;

	text-align: center;

	.modal-title {
		font-family: 'Lars', sans-serif;
		margin-bottom: 20px;
		font-size: 24px;
		text-align: center;
	}

	.modal-description {
		font-family: 'Lars', sans-serif;
		font-size: 14px;
		margin-bottom: 20px;
	}

	.modal-input {
		appearance: none;

		border: 1px solid #31ffda;
		height: 27px;
		background-color: transparent;
		font-family: inherit;
		font-size: inherit;
		color: #31ffda;
		cursor: pointer;

		text-align: center;
	}

	input[type='number'] {
		&:focus {
			outline: none;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			appearance: none;
			display: none;
		}

		-moz-appearance: textfield;
		appearance: none;
	}

	.modal-input-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		.modal-button {
			border: 1px solid #31ffda;
			color: #31ffda;
			width: 27px;
			height: 27px;
			line-height: 10em;
			position: relative;

			&:hover {
				background-color: rgba(49, 255, 218, 0.2);
			}

			&.button-less {
				border-radius: 50px 0 0 50px;
				border-right: none;
			}

			&.button-more {
				border-radius: 0 50px 50px 0;
				border-left: none;
			}

			&::after {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 27px;
				line-height: 25px;
			}

			&.button-less::after {
				content: '-';
			}

			&.button-more::after {
				content: '+';
			}
		}
	}

	.modal-buttons {
		display: flex;
		gap: 20px;
		align-items: center;
		justify-content: center;
	}

	button {
		white-space: nowrap;
		border: 1px solid #fff;
		border-radius: 50px;
		appearance: none;
		background-color: transparent;
		font-family: inherit;
		font-size: inherit;
		color: inherit;
		cursor: pointer;
		font-size: 13px;
		font-family: 'Lars Mono', monospace;
		padding: 4px 9px;
		transition: background 0.1s ease;
		display: flex;
		align-items: center;
		line-height: 1em;
		box-sizing: border-box;
		min-width: 27px;
		min-height: 27px;
		background-position: center;
		background-repeat: no-repeat;

		&:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
}

.wallet-select {
	display: none;

	&::before {
		content: 'Connect Wallet';
		font-family: 'Lars', sans-serif;
		color: #fff;
		margin-bottom: 20px;
		font-size: 24px;
	}

	&.visible {
		display: flex;
	}

	& > div {
		font-size: 16px;
		padding: 10px 20px;
		background: #fff0;
		border: 1px solid #fff;
		color: #fff;
		border-radius: 100px;
		cursor: pointer;
		transition: background 0.1s ease;

		&:hover {
			background: #fff2;
		}
	}
}

.notification.notification-error {
	background: #ff2b7c;
	color: #fff;
}

.auction-status {
	font-size: 12px;

	a {
		color: #31ffda;
	}
}

// Hide phone stuff
@media screen and (max-width: 800px) {
	// .header-upper {
	// 	// twice as high
	// 	height: 90px !important;
	// 	flex-wrap: wrap !important;
	// 	align-items: flex-end !important;
	// 	padding-right: 0 !important;

	// 	.header-section-market {
	// 		margin-bottom: 10px;
	// 	}
	// }

	.button-explore {
		display: none !important;
	}

	.button-bookmark {
		width: 27px;

		.icon-bookmark {
			left: 12.5px !important;
		}
	}

	.button-bookmark::after {
		content: '' !important;
	}

	.header-lower {
		display: grid !important;
		grid-template-columns: 1fr 1fr !important;
		grid-template-rows: 1fr 1fr !important;
		grid-template-areas:
			'title mint'
			'stats mint';
		// display: flex !important;
		// flex-direction: column !important;
		// align-items: flex-start !important;
		// justify-content: center !important;
		// padding: 7px 20px !important;
		// box-sizing: border-box;

		.header-section-title {
			// order: 0;

			grid-area: title;
		}

		.header-section-stats {
			// order: 1;
			// width: 100%;

			grid-area: stats;
		}

		.header-section-action {
			grid-area: mint;
			// order: 2;
			// width: 100%;
			padding-right: 37px;
		}

		.button-bookmark {
			margin-left: auto !important;
			position: absolute;
			right: 20px;

			top: 50%;
			transform: translateY(-50%);
		}
	}
}

@media screen and (max-width: 570px) {
	.overlay-visible + .ui-toggle-wrapper {
		display: none !important;
	}
}
