@font-face {
	font-family: 'Lars';
	font-weight: normal;
	src: url('../../fonts/Lars-Regular.woff2') format('woff2'),
		url('../../fonts/Lars-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Lars';
	font-weight: bold;
	src: url('../../fonts/Lars-Medium.woff2') format('woff2'),
		url('../../fonts/Lars-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Lars Mono';
	src: url('../../fonts/Lars-Mono.woff2') format('woff2'),
		url('../../fonts/Lars-Mono.woff') format('woff');
}
