body {
	// display: flex;
	// flex-direction: column;
}

* {
	user-select: none;
}

h2,
h3,
ul,
a,
p,
em,
li,
i {
	user-select: text;
}

i {
	font-style: italic;
}

h2 {
	// text-decoration: underline;
	font-weight: bold !important;
	font-size: 1.2em !important;
}

h3 {
	// font-weight: bold !important;
	font-family: 'Lars Mono' !important;
	font-size: 0.8em !important;
}

h2 + h3 {
	margin-top: 0.95em;
}

h2 + p,
h2 + ul {
	margin-top: 0.95em;
}

h3 + p {
	margin-top: 0.2em;
}

p:first-child {
	margin-top: 1em;
}

p + p,
p + ul,
ul + p {
	margin-top: 0.75em;
}

p + h2,
ul + h2 {
	margin-top: 2.5em;
}

p + h3,
ul + h3 {
	margin-top: 1.5em;
}

ul,
p {
	line-height: 1.35em !important;
}

ul {
	list-style: disc !important;

	li {
		margin-left: 1em;
	}
}

em {
	font-style: italic;
	// text-decoration: underline;
}
