html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Lars;
  font-weight: normal;
  src: url("Lars-Regular.214acd5d.woff2") format("woff2"), url("Lars-Regular.c43a8ba5.woff") format("woff");
}

@font-face {
  font-family: Lars;
  font-weight: bold;
  src: url("Lars-Medium.3e552125.woff2") format("woff2"), url("Lars-Medium.aa851172.woff") format("woff");
}

@font-face {
  font-family: Lars Mono;
  src: url("Lars-Mono.04ae104a.woff2") format("woff2"), url("Lars-Mono.b594ffe0.woff") format("woff");
}

.ui, .notifications {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-family: Lars, sans-serif;
  font-size: 18px;
}

a {
  color: inherit;
}

.ui.visible + .ui-toggle-wrapper .ui-toggle {
  box-shadow: none;
  background-color: #0000;
  transform: rotate(90deg);
}

.ui-toggle-wrapper {
  z-index: 9999;
  box-sizing: border-box;
  align-items: center;
  height: 45px;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.ui-toggle-wrapper .ui-toggle {
  white-space: nowrap;
  appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #0003;
  background-image: url("icon-back.c6f85628.svg");
  background-position: 45%;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  border-radius: 50px;
  width: 27px;
  height: 27px;
  font-family: Lars Mono, monospace;
  font-size: 13px;
  transition: background .1s, transform .3s;
  display: flex;
  transform: rotate(-90deg);
  box-shadow: 0 0 10px 4px #0000004d;
}

.ui-toggle-wrapper .ui-toggle:hover {
  background-color: #fff3;
}

.ui {
  z-index: 2000;
  width: 100%;
  transition: transform .3s cubic-bezier(.455, .03, .515, .955);
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.ui.visible {
  transform: translateY(0);
}

.ui .container {
  flex: 1;
}

.ui .network-stats {
  mix-blend-mode: normal;
  -webkit-backdrop-filter: blur(10px);
  color: #fff;
  box-sizing: border-box;
  background: #0003;
  border-radius: 10px;
  flex-direction: column;
  gap: 1em;
  width: 302px;
  padding: 20px;
  display: none;
  position: absolute;
  top: 145px;
  left: 40px;
}

.ui .network-stats.visible {
  display: flex;
}

.ui .network-stats .network-stat {
  flex-direction: column;
  flex: 1;
  gap: .3em;
  display: flex;
}

.ui .network-stats .graph {
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 20px;
  width: calc(100% + 10px);
  height: 17px;
  margin-left: -5px;
  overflow: hidden;
}

.ui .network-stats .graph .graph-fill {
  background-color: #fff;
  border-radius: 20px;
  height: 100%;
  margin-left: -100px;
  padding-left: 100px;
}

.ui .network-stats .value {
  font-size: 13px;
}

.ui .network-stats .label {
  color: #fff;
  opacity: .7;
  font-family: Lars Mono, monospace;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.ui .network-stats .network-stat-discovered .graph {
  border-color: #fff;
}

.ui .network-stats .network-stat-discovered .graph .graph-fill {
  background-color: #fff;
}

.ui .network-stats .network-stat-bookmarked .graph {
  border-color: #a850ff;
}

.ui .network-stats .network-stat-bookmarked .graph .graph-fill {
  background-color: #a850ff;
}

.ui .network-stats .network-stat-minted .graph {
  border-color: #31ffda;
}

.ui .network-stats .network-stat-minted .graph .graph-fill {
  background-color: #31ffda;
}

.ui .header-upper, .ui .header-lower {
  color: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  align-items: center;
  height: 45px;
  padding: 0 20px;
  display: flex;
}

.ui .header-upper {
  background: #0006;
  padding: 0 57px;
  position: relative;
}

.ui .header-lower {
  z-index: -1;
  border-radius: 0 0 10px 10px;
  height: 45px;
  padding: 0 20px;
  position: relative;
  overflow: hidden;
}

.ui .header-lower.minted, .ui .header-lower.proof {
  color: #000;
}

.ui .header-lower-background, .ui .header-lower-background div {
  z-index: -1;
  transition: top .15s cubic-bezier(.455, .03, .515, .955);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.ui .header-lower-background {
  top: 0;
}

.ui .header-lower-background div {
  height: 200%;
}

.ui .header-lower-background-main {
  z-index: -3;
  background: #00000026;
  top: 0;
}

.ui .header-lower-background-bookmarked {
  z-index: -2;
  background: linear-gradient(#a850ff00 0%, #a850ff 50% 100%) no-repeat;
  top: 100%;
}

.ui .header-lower-background-minted {
  z-index: -1;
  background: linear-gradient(#31ffda00 0%, #31ffda 50% 100%) no-repeat;
  top: 100%;
}

.ui .header-lower-background-proof {
  z-index: -1;
  background: linear-gradient(#ffba1200 0%, #ffba12 50% 100%) no-repeat;
  top: 100%;
}

.ui .header-lower.bookmarked .header-lower-background-bookmarked, .ui .header-lower.minted .header-lower-background-minted, .ui .header-lower.proof .header-lower-background-proof {
  top: -100%;
}

.ui button {
  white-space: nowrap;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  border-radius: 50px;
  align-items: center;
  min-width: 27px;
  min-height: 27px;
  padding: 4px 9px;
  font-family: Lars Mono, monospace;
  font-size: 13px;
  line-height: 1em;
  transition: background .1s;
  display: flex;
}

.ui button:hover {
  background-color: #fff3;
}

.ui .button-big {
  font-family: Lars, sans-serif;
}

.ui .header-section-about, .ui .header-section-nav, .ui .header-section-market, .ui .header-section-stats, .ui .header-section-title, .ui .header-section-action {
  flex: 1;
}

.ui .header-section-about {
  gap: 12px;
  display: flex;
}

.ui .header-section-nav {
  flex: 0;
}

.ui .header-section-title {
  white-space: nowrap;
  flex: 0;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
}

.ui .stats {
  align-items: center;
  font-family: Lars Mono, monospace;
  font-size: 13px;
  display: flex;
}

.ui .stats .stats-views, .ui .stats .stats-bookmarks {
  opacity: .6;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.ui .stats .stats-views:before {
  content: "";
  background: #fff;
  width: 20px;
  height: 12px;
  margin-right: 5px;
  display: block;
  -webkit-mask-image: url("icon-view.bf52f588.svg");
  mask-image: url("icon-view.bf52f588.svg");
}

.ui .stats .stats-bookmarks:before {
  content: "";
  background: #fff;
  width: 13px;
  height: 12px;
  margin-right: 5px;
  display: block;
  -webkit-mask-image: url("icon-bookmark.afbc0a01.svg");
  mask-image: url("icon-bookmark.afbc0a01.svg");
}

.ui .header-section-market, .ui .header-section-action {
  justify-content: flex-end;
  align-items: center;
  font-family: Lars Mono, monospace;
  display: flex;
}

.ui .icon-bookmark {
  background-image: linear-gradient(#fff0 0, #fff 1px);
  background-position: 0 12px;
  background-repeat: no-repeat;
  width: 13px;
  height: 12px;
  margin-right: 10px;
  transition: background-position .3s cubic-bezier(.455, .03, .515, .955);
  display: block;
  position: relative;
  -webkit-mask-image: url("icon-bookmark-filled.621315a9.svg");
  mask-image: url("icon-bookmark-filled.621315a9.svg");
}

.ui .header-lower.minted .icon-bookmark, .ui .header-lower.proof .icon-bookmark {
  background-image: linear-gradient(#0000 0, #000 1px);
}

.ui .header-lower.bookmarked .button-bookmark {
  background: #fff3;
}

.ui .button-bookmark {
  position: relative;
}

.ui .button-bookmark:after {
  content: "Bookmark";
  margin-left: 20px;
}

.ui .header-lower.minted .stats-views:before, .ui .header-lower.minted .stats-bookmarks:before, .ui .header-lower.proof .stats-views:before, .ui .header-lower.proof .stats-bookmarks:before {
  background: #000;
}

.ui .header-lower.minted .button-bookmark, .ui .header-lower.proof .button-bookmark {
  border: 1px solid #000;
}

.ui .button-bookmark .icon-bookmark {
  width: 13px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 13.5px;
  transform: translate(-50%, -50%);
}

.ui .button-bookmark .icon-bookmark:before {
  content: "";
  background-color: #fff;
  width: 13px;
  height: 12px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: url("icon-bookmark.afbc0a01.svg");
  mask-image: url("icon-bookmark.afbc0a01.svg");
}

.ui .header-lower.minted .button-bookmark .icon-bookmark:before, .ui .header-lower.proof .button-bookmark .icon-bookmark:before {
  background: #000;
}

.ui .header-lower.bookmarked .button-bookmark .icon-bookmark {
  background-position: 0 0;
}

.ui .header-lower.bookmarked.minted .button-bookmark, .ui .header-lower.minted .button-bookmark:hover, .ui .header-lower.bookmarked.proof .button-bookmark, .ui .header-lower.proof .button-bookmark:hover {
  background: #0003;
}

.ui .overlay-controls {
  z-index: 1;
  align-items: center;
  gap: 10px;
  height: 45px;
  margin-left: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.ui .overlay {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  box-sizing: border-box;
  color: #fff;
  background: #0009;
  border-radius: 0 10px 10px 0;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  padding: 0 20px;
  transition: transform .3s cubic-bezier(.455, .03, .515, .955), box-shadow .3s cubic-bezier(.455, .03, .515, .955);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  box-shadow: 0 0 50px #0000;
}

.ui .overlay a {
  color: inherit;
}

.ui .overlay.visible {
  transform: translateX(0);
  box-shadow: 0 0 50px #000000b3;
}

.ui .overlay .top-bar {
  flex: 0 0 45px;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
  display: flex;
}

.ui .overlay .overlay-title, .ui .overlay .overlay-title-2 {
  border-top: .25px solid #ffffff40;
  border-bottom: .25px solid #ffffff40;
  flex: 0 0 44px;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-left: -20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
}

.ui .about-content, .ui .manual-content {
  padding: 20px 0;
  overflow: scroll;
}

.ui em {
  font-style: italic;
}

.ui .overlay-bookmarks .bookmarks, .ui .overlay-bookmarks .mints {
  flex: 1;
  width: 100%;
  margin-left: -20px;
  padding: 0 20px;
  overflow: scroll;
}

.ui .overlay-bookmarks .bookmarks .bookmark, .ui .overlay-bookmarks .mints .bookmark {
  cursor: pointer;
  border-bottom: .25px solid #ffffff40;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-left: -20px;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.ui .overlay-bookmarks .bookmarks .bookmark:hover, .ui .overlay-bookmarks .mints .bookmark:hover {
  background: #fff3;
}

.ui .overlay-bookmarks .bookmarks .bookmark .bookmark-metadata, .ui .overlay-bookmarks .mints .bookmark .bookmark-metadata {
  flex-direction: column;
  gap: 6px;
  display: flex;
}

.ui .overlay-bookmarks .bookmarks .bookmark .bookmark-metadata .bookmark-title, .ui .overlay-bookmarks .mints .bookmark .bookmark-metadata .bookmark-title {
  font-style: italic;
  font-weight: bold;
}

.ui .overlay-bookmarks .bookmarks .bookmark .bookmark-icon, .ui .overlay-bookmarks .mints .bookmark .bookmark-icon {
  width: 16px;
  height: 16px;
}

.ui .overlay-bookmarks .overlay-title {
  background-color: #a850ff;
}

.ui .overlay-bookmarks .overlay-title-2 {
  color: #000;
  background-color: #31ffda;
}

.ui .header-lower.minted .mint-price {
  display: none;
}

.ui .mint-price {
  white-space: nowrap;
  margin-right: 20px;
}

.ui .button-bookmarks {
  background-image: url("icon-bookmarks.9cdea187.svg");
}

.ui .button-about {
  background-image: url("icon-about.5b35a961.svg");
}

.ui .button-manual {
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 27px;
  padding: 0;
  font-family: Lars Mono, monospace;
  display: flex;
}

.ui .button-close {
  background-image: url("icon-close.202093d3.svg");
}

.ui canvas {
  display: block;
}

.ui .button-about, .ui .button-bookmarks {
  z-index: 9999;
}

.ui .button-about.active, .ui .button-bookmarks.active {
  background-color: #fff3;
}

.ui .button-explore:after {
  content: "Web";
}

.ui .button-explore.active:after {
  content: "Back to Webpage";
}

.ui .button-explore.active:before {
  content: " ";
  background-image: url("icon-back.c6f85628.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 100%;
  margin-right: 10px;
  display: block;
}

.ui .header-section-market {
  gap: 10px;
}

.ui .market-information {
  white-space: nowrap;
  position: relative;
}

.ui .button-wallet.button-wallet-disconnect {
  white-space: nowrap;
  max-width: 250px;
  height: 27px;
  overflow: hidden;
}

.ui .button-wallet.button-wallet-disconnect div {
  text-align: center;
  flex-direction: column;
  align-items: center;
  height: 54px;
  transition: transform .3s cubic-bezier(.455, .03, .515, .955);
  display: flex;
  transform: translateY(25%);
}

.ui .button-wallet.button-wallet-disconnect div span {
  align-items: center;
  height: 27px;
  display: flex;
}

.ui .button-wallet.button-wallet-disconnect:hover div {
  transform: translateY(-25%);
}

.ui .header-lower.minted .button-mint {
  display: none;
}

.ui .button-mint, .ui .button-mint-random {
  color: #31ffda;
  border-color: #31ffda;
}

.ui .button-mint:hover, .ui .button-mint-random:hover {
  background-color: #31ffda33;
}

.ui .minted-by {
  font-size: 13px;
}

.notifications {
  z-index: 10000;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 40px);
  max-width: 250px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@keyframes notification {
  0% {
    opacity: 0;
    margin-bottom: -80px;
  }

  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

.notifications .notification {
  box-sizing: border-box;
  background: #31ffda;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  height: 70px;
  margin-bottom: 0;
  padding: 0 10px;
  transition: transform .3s cubic-bezier(.455, .03, .515, .955);
  animation: .3s cubic-bezier(.455, .03, .515, .955) notification;
  display: flex;
  transform: translate(calc(100% + 20px));
}

.notifications .notification a {
  color: inherit;
}

.notifications .notification.notification-transaction {
  background: repeating-linear-gradient(90deg, #ce79ff 0, #31ffda 50%, #ce79ff 100%) 0 0 / 300px;
  animation: 2s linear infinite gradient;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -300px 0;
  }
}

.notifications .notification.notification-transaction .notification-title:after {
  content: "-";
  margin-left: 10px;
  font-family: Lars Mono, monospace;
  font-style: normal;
  animation: .5s linear infinite spinner;
}

@keyframes spinner {
  0% {
    content: "-";
  }

  25% {
    content: "\\";
  }

  50% {
    content: "|";
  }

  75% {
    content: "/";
  }

  100% {
    content: "-";
  }
}

.notifications .notification .notification-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 2px;
  font-style: italic;
  overflow: hidden;
}

.notifications .notification .labels {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.notifications .notification .labels .labeled {
  flex: auto;
  min-width: 40px;
}

.notifications .notification .labels .labeled .label {
  opacity: .4;
}

.notifications .notification .labels .labeled .value {
  white-space: nowrap;
  position: relative;
}

.notifications .notification.visible {
  transform: translate(0);
}

.notifications .notification .price {
  flex: auto;
}

.notifications .notification .price .value:after {
  content: " Ξ";
}

.notifications .notification .address .value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.map-container {
  z-index: 1000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  pointer-events: none;
  background: linear-gradient(#00000005 0%, #0000004d 20%);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.labeled {
  flex-direction: column;
  gap: 2px;
  display: flex;
}

.labeled .value {
  font-size: 13px;
}

.labeled .label {
  opacity: .7;
  font-size: 10px;
}

.map-container.visible {
  pointer-events: auto;
  display: block;
}

wcm-modal {
  z-index: 2147483647;
  position: fixed;
}

.green {
  color: #31ffda;
}

.modal-background {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 999999;
  background: #0009;
  display: none;
  position: fixed;
  inset: 0;
}

.modal-background.visible {
  display: flex;
}

.modal {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 9999999;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  background: #fff2;
  border: 1px solid #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  padding: 32px 48px;
  font-family: Lars Mono, monospace;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 20px 40px #0006;
}

.modal .modal-title {
  text-align: center;
  margin-bottom: 20px;
  font-family: Lars, sans-serif;
  font-size: 24px;
}

.modal .modal-description {
  margin-bottom: 20px;
  font-family: Lars, sans-serif;
  font-size: 14px;
}

.modal .modal-input {
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  color: #31ffda;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: 1px solid #31ffda;
  height: 27px;
}

.modal input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

.modal input[type="number"]:focus {
  outline: none;
}

.modal input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  display: none;
}

.modal input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  display: none;
}

.modal .modal-input-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.modal .modal-input-wrapper .modal-button {
  color: #31ffda;
  border: 1px solid #31ffda;
  width: 27px;
  height: 27px;
  line-height: 10em;
  position: relative;
}

.modal .modal-input-wrapper .modal-button:hover {
  background-color: #31ffda33;
}

.modal .modal-input-wrapper .modal-button.button-less {
  border-right: none;
  border-radius: 50px 0 0 50px;
}

.modal .modal-input-wrapper .modal-button.button-more {
  border-left: none;
  border-radius: 0 50px 50px 0;
}

.modal .modal-input-wrapper .modal-button:after {
  height: 27px;
  line-height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal .modal-input-wrapper .modal-button.button-less:after {
  content: "-";
}

.modal .modal-input-wrapper .modal-button.button-more:after {
  content: "+";
}

.modal .modal-buttons {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.modal button {
  white-space: nowrap;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  border-radius: 50px;
  align-items: center;
  min-width: 27px;
  min-height: 27px;
  padding: 4px 9px;
  font-family: Lars Mono, monospace;
  font-size: 13px;
  line-height: 1em;
  transition: background .1s;
  display: flex;
}

.modal button:hover {
  background-color: #fff3;
}

.wallet-select {
  display: none;
}

.wallet-select:before {
  content: "Connect Wallet";
  color: #fff;
  margin-bottom: 20px;
  font-family: Lars, sans-serif;
  font-size: 24px;
}

.wallet-select.visible {
  display: flex;
}

.wallet-select > div {
  color: #fff;
  cursor: pointer;
  background: #fff0;
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 10px 20px;
  font-size: 16px;
  transition: background .1s;
}

.wallet-select > div:hover {
  background: #fff2;
}

.notification.notification-error {
  color: #fff;
  background: #ff2b7c;
}

.auction-status {
  font-size: 12px;
}

.auction-status a {
  color: #31ffda;
}

@media screen and (width <= 800px) {
  .button-explore {
    display: none !important;
  }

  .button-bookmark {
    width: 27px;
  }

  .button-bookmark .icon-bookmark {
    left: 12.5px !important;
  }

  .button-bookmark:after {
    content: "" !important;
  }

  .header-lower {
    grid-template-areas: "title mint"
                         "stats mint";
    grid-template-rows: 1fr 1fr !important;
    grid-template-columns: 1fr 1fr !important;
    display: grid !important;
  }

  .header-lower .header-section-title {
    grid-area: title;
  }

  .header-lower .header-section-stats {
    grid-area: stats;
  }

  .header-lower .header-section-action {
    grid-area: mint;
    padding-right: 37px;
  }

  .header-lower .button-bookmark {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    margin-left: auto !important;
  }
}

@media screen and (width <= 570px) {
  .overlay-visible + .ui-toggle-wrapper {
    display: none !important;
  }
}

* {
  -webkit-user-select: none;
  user-select: none;
}

h2, h3, ul, a, p, em, li, i {
  -webkit-user-select: text;
  user-select: text;
}

i {
  font-style: italic;
}

h2 {
  font-size: 1.2em !important;
  font-weight: bold !important;
}

h3 {
  font-family: Lars Mono !important;
  font-size: .8em !important;
}

h2 + h3, h2 + p, h2 + ul {
  margin-top: .95em;
}

h3 + p {
  margin-top: .2em;
}

p:first-child {
  margin-top: 1em;
}

p + p, p + ul, ul + p {
  margin-top: .75em;
}

p + h2, ul + h2 {
  margin-top: 2.5em;
}

p + h3, ul + h3 {
  margin-top: 1.5em;
}

ul, p {
  line-height: 1.35em !important;
}

ul {
  list-style: disc !important;
}

ul li {
  margin-left: 1em;
}

em {
  font-style: italic;
}

/*# sourceMappingURL=index.53b7f1aa.css.map */
